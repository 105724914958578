<template>
    <client-page>

        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                <business-item imageGrid="4" tit="성북50플러스센터" file="sb" url="https://www.50plus.or.kr/sbc/index.do">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인여가시설(노인교실)
                        </li>
                        <li class="dot-text">
                            소재지 : 성북구 지봉로 24길 26
                        </li>
                        <li class="dot-text">
                            시설규모 : 1,072.36㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2019. 2. 12
                        </li>
                        <li class="dot-text">
                            이용정원 : 200명
                        </li>
                        <li class="dot-text">
                            시설장 : 박익현
                        </li>
                        <li class="dot-text">
                            종사자 : 9명
                        </li>
                        <li class="dot-text">
                            주요사업 : 인생설계사업, 일·활동사업, 당사자지원사업, 민관협력 네트워트 구축 사업 등
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="강북50플러스센터" file="gb" url="https://www.50plus.or.kr/gbc/index.do">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인여가시설(노인교실)
                        </li>
                        <li class="dot-text">
                            소재지 : 강북구 오현로 9길 49
                        </li>
                        <li class="dot-text">
                            시설규모 : 1,002.38㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2022. 10. 31
                        </li>
                        <li class="dot-text">
                            이용정원 : 200명
                        </li>
                        <li class="dot-text">
                            시설장 : 이재영
                        </li>
                        <li class="dot-text">
                            종사자 : 9명
                        </li>
                        <li class="dot-text">
                            주요사업 : 인생설계사업, 일·활동사업, 당사자지원사업, 민관협력 네트워트 구축 사업 등
                        </li>
                    </ul>
                </business-item>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from '@/components/client/sub/tab-business-domestic.vue';
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>