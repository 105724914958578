var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-business-domestic'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title-operate'), _c('tab-business-domestic'), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "성북50플러스센터",
      "file": "sb",
      "url": "https://www.50plus.or.kr/sbc/index.do"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인여가시설(노인교실) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 성북구 지봉로 24길 26 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 1,072.36㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2019. 2. 12 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 200명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 박익현 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 9명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 인생설계사업, 일·활동사업, 당사자지원사업, 민관협력 네트워트 구축 사업 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "강북50플러스센터",
      "file": "gb",
      "url": "https://www.50plus.or.kr/gbc/index.do"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인여가시설(노인교실) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 강북구 오현로 9길 49 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 1,002.38㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2022. 10. 31 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 200명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 이재영 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 9명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 인생설계사업, 일·활동사업, 당사자지원사업, 민관협력 네트워트 구축 사업 등 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }